import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"
import { navigate } from "gatsby"

// Import assets
import DepthRoundElementDark from "../../../assets/images/illustrations/depth-round-element-dark.svg"

// Import components
import PrimaryButton from "../../atoms/Button"

import { sm, md, lg, xl } from "../../../utils/mediaQueries"

const CTASection = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;

  background-image: url(${DepthRoundElementDark}), url(${DepthRoundElementDark});
  background-position: left 90% top -30rem, right 90% bottom -20rem;
  background-size: 60rem auto, 40rem auto;
  background-repeat: no-repeat, no-repeat;

  ${systemCss({
    bg: "purple"
  })}

  ${xl} {
    background-position: left 90% top -20rem, right 90% bottom -14rem;
    background-size: 40rem auto, 28rem auto;
  }

  ${sm} {
    background-position: left 90% top -14rem, right 90% bottom -10rem;
    background-size: 24rem auto, 20rem auto;
  }
`

const CTASectionContainer = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0px auto;
  flex-direction: column;
  padding: 14rem 7rem;
  justify-content: flex-start;
  align-items: center;

  ${xl} {
    padding: 7rem 4rem;
  }

  ${sm} {
    padding: 5rem 2rem;
  }
`

const SectionHeading = styled.h1`
  font-weight: 700;
  text-align: center;
  line-height: 1.25;

  ${systemCss({
    color: "white",
    fontSize: ["h3", "h3", "h2", "h1"]
  })}

  & > span {
    ${systemCss({
      color: "yellow"
    })}
  }
`

const SectionSubheading = styled.h3`
  margin: 0px;
  padding: 0px 4rem;
  font-weight: 400;
  margin-top: 2.5rem;
  line-height: 1.25;
  text-align: center;

  ${systemCss({
    color: "white",
    fontSize: ["p", "h4", "h4", "h3"]
  })}

  ${xl} {
    padding: 0px 2rem;
  }

  ${lg} {
    margin-top: 2rem;
  }

  ${sm} {
    ${systemCss({
      marginTop: "1rem"
    })}
  }
`

const CTAButton = styled(PrimaryButton)`
  margin-top: 6rem;

  ${systemCss({
    bg: "white",
    color: "purple"
  })}

  ${lg} {
    margin-top: 4rem;
  }

  ${sm} {
    margin-top: 3rem;
    ${systemCss({
      fontSize: "btn.md",
      padding: "btn.md",
      height: "btn.md"
    })}
  }
`

export default props => {
  const navigateToContact = () => navigate("/contact-us")
  return (
    <CTASection>
      <CTASectionContainer>
        <SectionHeading>
          Let’s Build Better Products <span>Together.</span>
        </SectionHeading>
        <SectionSubheading>
          Let our expertise help you discover better solutions and shape your
          ideas into great products for the customers.
        </SectionSubheading>
        <CTAButton type="button" onClick={navigateToContact}>
          Let's Begin
        </CTAButton>
      </CTASectionContainer>
    </CTASection>
  )
}
