import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

// Import assets
import DepthInfinityElement1 from "../../../assets/images/illustrations/depth-infinity-element1.svg"
import DepthInfinityElement3 from "../../../assets/images/illustrations/depth-infinity-element3.svg"

// Core values illustrations
import CollaborationIcon from "../../../assets/images/core-values/collaboration-icon.svg"
import DedicatedTeamIcon from "../../../assets/images/core-values/dedicated-teams-icon.svg"
import EconomicalPricingIcon from "../../../assets/images/core-values/economical-pricing-icon.svg"
import FeedbackDrivenIcon from "../../../assets/images/core-values/feedback-driven-icon.svg"
import GreatPlaceIcon from "../../../assets/images/core-values/great-place-icon.svg"
import HandOffIcon from "../../../assets/images/core-values/hand-off-icon.svg"
import TimelyDeliveryIcon from "../../../assets/images/core-values/timely-delivery-icon.svg"
import UxDrivenIcon from "../../../assets/images/core-values/UX-driven-icon.svg"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

// Import components
import ValueCard from "../../molecules/ValueCard"

// Styles
const ValueSection = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
`

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 1440px;
  margin: 0px auto;
  padding: 7rem 7rem;

  background-image: url(${DepthInfinityElement1}), url(${DepthInfinityElement3});
  background-position: right 0 top 12.5rem, left 0 bottom 0;
  background-size: 18rem auto, 18rem auto;
  background-repeat: no-repeat, no-repeat;

  ${xl} {
    padding: 5rem 4rem;

    background-position: right 0 top 12.5rem, left 0 bottom 0;
    background-size: 14rem auto, 14rem auto;
  }

  ${lg} {
    background-position: right 0 top 10rem, left 0 bottom 0;
    background-size: 14rem auto, 14rem auto;
  }

  ${md} {
    background-position: right 0 top 8rem, left 0 bottom 0;
    background-size: 10rem auto, 10rem auto;
  }

  ${sm} {
    padding: 3rem 2rem;
    background-position: right 0 top 8rem, left 0 bottom 0;
    background-size: 8rem auto, 8rem auto;
  }
`

const SectionHeading = styled.h2`
  text-align: center;
  font-weight: 700;
  line-height: 1.25;

  ${systemCss({
    fontSize: ["h3", "h2"],
    fontFamily: "primary",
    color: "purple"
  })}
`

const ValuesWrapper = styled.div`
  width: 100%;
  margin-top: 8rem;
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  grid-auto-rows: auto;
  justify-content: center;

  ${lg} {
    margin-top: 4rem;
  }

  ${sm} {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  }
`

const coreValues = [
  {
    illustration: EconomicalPricingIcon,
    heading: "Economical Pricing"
  },
  {
    illustration: TimelyDeliveryIcon,
    heading: "Timely Delivery"
  },
  {
    illustration: HandOffIcon,
    heading: "Smooth Hand Off"
  },
  {
    illustration: FeedbackDrivenIcon,
    heading: "Feedback Driven Development"
  },
  {
    illustration: DedicatedTeamIcon,
    heading: "Dedicated Teams"
  },
  {
    illustration: CollaborationIcon,
    heading: "Effective Collaboration"
  },
  {
    illustration: UxDrivenIcon,
    heading: "UX Rich Designs"
  },
  {
    illustration: GreatPlaceIcon,
    heading: "Great Place to Work"
  }
]

export default () => {
  return (
    <ValueSection>
      <ValueContainer>
        <SectionHeading>Our Core Value</SectionHeading>
        <ValuesWrapper>
          {Array.isArray(coreValues) &&
            coreValues.map((value, i) => {
              const { illustration, heading } = value
              return (
                <ValueCard
                  key={i}
                  illustration={illustration}
                  heading={heading}
                />
              )
            })}
        </ValuesWrapper>
      </ValueContainer>
    </ValueSection>
  )
}
