import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

// Media queries utils
import { sm, md, xl, lg } from "../../../utils/mediaQueries"

const BeliefCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;

  ${xl} {
    align-items: center;
  }
`

const BeliefCardImage = styled.img`
  max-height: 14rem;
  width: auto;

  ${sm} {
    max-height: 9.6rem;
  }
`

const BeliefCardHeading = styled.h4`
  margin-top: 2.4rem;
  font-weight: 700;
  line-height: 1.25;
  text-align: left;

  ${systemCss({
    fontSize: ["h5", "h4"],
    color: "dark"
  })}

  ${lg} {
    margin-top: 1.6rem;
    text-align: center;
  }
`

const BeliefCardDescription = styled.p`
  margin-top: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: left;

  ${systemCss({
    fontSize: ["link", "p"],
    color: "dark"
  })}

  ${xl} {
    text-align: center;
  }
  ${lg} {
    margin-top: 1.6rem;
  }

  ${lg} {
    margin-top: 0.8rem;
  }
`

export default props => {
  const { illustration, heading, description } = props

  return (
    <BeliefCard>
      <BeliefCardImage src={illustration} alt="core value illustration" />
      <BeliefCardHeading>{heading}</BeliefCardHeading>
      <BeliefCardDescription>{description}</BeliefCardDescription>
    </BeliefCard>
  )
}
