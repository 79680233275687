import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

// Import assets
import DepthInfinityElement1 from "../../../assets/images/illustrations/depth-infinity-element1.svg"

// Core beliefs
import CustomerCentricIcon from "../../../assets/images/core-belief/customer-centric-icon.svg"
import QuilityIcon from "../../../assets/images/core-belief/Quality-icon.svg"
import LatestTechIcon from "../../../assets/images/core-belief/technology-icon.svg"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

// Import components
import BeliefCard from "../../molecules/BeliefCard"

// Styles
const BeliefSection = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;

  background-image: url(${DepthInfinityElement1});
  background-position: right -10rem top 0rem;
  background-size: 32rem auto;
  background-repeat: no-repeat;

  ${xl} {
    background-position: right -6rem top 0rem;
    background-size: 24rem auto;
  }

  ${lg} {
    background-position: right -4rem top 0rem;
    background-size: 16rem auto;
  }

  ${md} {
    background-position: right -2rem top 15%;
    background-size: 12rem auto;
  }
`

const BeliefContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 1440px;
  margin: 0px auto;
  padding: 7rem 7rem;

  ${xl} {
    padding: 5rem 4rem;
  }

  ${sm} {
    padding: 3rem 2rem;
  }
`

const SectionHeading = styled.h2`
  text-align: center;
  font-weight: 700;
  line-height: 1.25;

  ${systemCss({
    fontSize: ["h3", "h2"],
    fontFamily: "primary",
    color: "purple"
  })}
`

const BeliefsWrapper = styled.div`
  margin: 8rem -2.5rem -6rem;

  ${lg} {
    margin: 8rem -1rem -6rem;
  }

  ${sm} {
    margin: 4rem 0px -3rem;
  }
`

const BeliefsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
`

const BeliefCardWrapper = styled.div`
  padding: 0 2.5rem 6rem;
  flex-basis: 33.3%;

  ${xl} {
    flex-basis: 50%;
  }

  ${lg} {
    padding: 0 1rem 6rem;
  }

  ${md} {
    flex-basis: 100%;
  }

  ${sm} {
    padding: 0px 0px 3rem;
  }
`

const coreBelief = [
  {
    illustration: CustomerCentricIcon,
    heading: "Client-Centric Approach",
    description:
      "Being a customer-centric company, we carry out business activities in a way that promotes great and positive customer experience before and after the delivery of projects. This way, we assure you to nurture the culture of repeating customers as well as customer loyalty."
  },
  {
    illustration: LatestTechIcon,
    heading: "Cutting Edge Technology",
    description:
      "We prefer using the latest technologies and tools available, which allows us to adapt to the market and provide optimal solutions for your problems. We want you to grow with you in building better products for end-users."
  },
  {
    illustration: QuilityIcon,
    heading: "Quality of Deliverables",
    description:
      "With our internal framework and methodologies, we make sure that the quality of deliverables is up to industry standards at all times. We define and deliver on time."
  }
]

export default () => {
  return (
    <BeliefSection>
      <BeliefContainer>
        <SectionHeading>Beliefs of every tenxgeek</SectionHeading>
        <BeliefsWrapper>
          <BeliefsContainer>
            {Array.isArray(coreBelief) &&
              coreBelief.map((belief, i) => {
                const { illustration, heading, description } = belief
                return (
                  <BeliefCardWrapper key={i}>
                    <BeliefCard
                      key={i}
                      illustration={illustration}
                      heading={heading}
                      description={description}
                    />
                  </BeliefCardWrapper>
                )
              })}
          </BeliefsContainer>
        </BeliefsWrapper>
      </BeliefContainer>
    </BeliefSection>
  )
}
