import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

// Import assets

const TeamMemberCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TeamMemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`

const TeamMemberImage = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.img});
  border-radius: 2rem;
  box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.1);
`

const MemberDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;

  ${sm} {
    margin-top: 1.6rem;
  }
`

const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const Designation = styled.h5`
  font-weight: 400;
  line-height: 1.25;

  ${systemCss({
    fontSize: ["link", "link", "link", "h5"],
    color: "dark"
  })}
`

const Name = styled.h3`
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  margin-top: 0.8rem;

  ${systemCss({
    fontSize: ["h4", "h4", "h4", "h3"],
    color: "purple"
  })}
`

const MemberSocialHandles = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 0.8rem;
`

const SocialHandle = styled.a`
  &:not(:last-of-type) {
    margin-right: 0.8rem;
    cursor: pointer;
  }

  & > img {
    display: block;
    height: 2.8rem;
    width: auto;
  }
`

const MemberHeadline = styled.p`
  margin-top: 1rem;
  font-weight: 400;
  line-height: 1.25;

  ${systemCss({
    fontSize: ["link", "link", "link", "p"],
    color: "dark"
  })}

  ${sm} {
    margin-top: 0.8rem;
  }
`

export default props => {
  const { name, designation, headline, image, socialHandles } = props

  return (
    <TeamMemberCard>
      <TeamMemberContainer>
        <TeamMemberImage img={image} />
        <MemberDetails>
          <MemberInfo>
            <Designation>{designation}</Designation>
            <Name>{name}</Name>
          </MemberInfo>
          <MemberSocialHandles>
            {Array.isArray(socialHandles) &&
              socialHandles.map((socialHandle, i) => {
                const { icon, url } = socialHandle
                return (
                  <SocialHandle
                    key={i}
                    href={url || "#"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={icon} alt="social handle" />
                  </SocialHandle>
                )
              })}
          </MemberSocialHandles>
        </MemberDetails>
        <MemberHeadline>{headline}</MemberHeadline>
      </TeamMemberContainer>
    </TeamMemberCard>
  )
}
