import React from "react"
import styled from "@emotion/styled"
import withTheme from "@styled-system/css"
import { Link } from "gatsby"

// Import assets
import DepthInfinityElement1 from "../../../assets/images/illustrations/depth-infinity-element1.svg"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

// Import components
import StatementSection from "./StatementSection"
import BeliefSection from "./BeliefSection"
import ValuesSection from "./ValuesSection"
import TeamSection from "./TeamSection"

// Styles
const PageHeaderSection = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
`

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1440px;
  margin: 0px auto;
  padding: 20rem 7rem 8rem;

  ${xl} {
    padding: 14rem 4rem 5rem;
  }

  ${lg} {
    padding: 10rem 4rem 4rem;
  }

  ${sm} {
    padding: 6rem 2rem 2rem;
  }
`

const PageHeading = styled.h1`
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  max-width: 92.5rem;

  ${withTheme({
    fontSize: ["h3", "h2", "h2", "h2", "h1"],
    color: "black",
    fontFamily: "primary"
  })}

  ${lg} {
    max-width: 62rem;
  }

  ${sm} {
    max-width: 100%;
  }
`

const PageSubheading = styled.h4`
  margin-top: 2rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  max-width: 92.5rem;

  ${withTheme({
    fontSize: ["p", "h5", "h5", "h4"],
    color: "dark",
    fontFamily: "primary"
  })}

  ${lg} {
    max-width: 62rem;
  }

  ${sm} {
    margin-top: 1rem;
    max-width: 100%;
  }
`

const SectionHeading = styled.h2`
  font-weight: 700;
  line-height: 1.25;
  padding: 0px;
  margin: 0px;
  text-align: center;

  ${withTheme({
    color: "purple",
    fontSize: "h2",
    fontFamily: "primary"
  })}

  ${sm} {
    ${withTheme({
      fontSize: "h3"
    })}
  }
`

const CoreValueSection = styled.div`
  display: block;
  width: 100%;
`

const CoreValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 1440px;
  margin: 0px auto;
  padding: 7rem 7rem;

  ${xl} {
    padding: 5rem 4rem;
  }

  ${sm} {
    padding: 3rem 2rem;
  }
`

const CoreValuesWrapper = styled.div`
  width: 100%;
  margin-top: 8rem;
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 24rem));
  grid-auto-rows: auto;
  justify-content: center;
`

const CoreBeliefsWrapper = styled.div`
  width: 100%;
  margin-top: 8rem;
  display: grid;
  grid-gap: 7rem;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  grid-auto-rows: auto;
  justify-content: center;
`

const AboutIllustration = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  max-width: 50%;
  display: flex;
  padding: 0px 1rem;
  align-items: center;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  ${lg} {
    max-width: 80%;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  ${sm} {
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
`

const AboutContent = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  max-width: 50%;
  padding: 0px 1rem;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${lg} {
    max-width: 80%;
    padding: 0px 1rem;
    margin-top: 4rem;
  }

  ${sm} {
    max-width: 95%;
    margin-top: 2.5rem;
  }
`

const AboutHeading = styled.h2`
  font-weight: 700;
  line-height: 1.25;
  padding: 0px;
  margin: 0px;

  ${withTheme({
    color: "purple",
    fontSize: "h2",
    fontFamily: "primary"
  })}

  ${sm} {
    ${withTheme({
      fontSize: "h3"
    })}
  }
`

const AboutDescription = styled.h4`
  font-weight: 400;
  line-height: 1.2;
  padding: 0px;
  margin: 0px;
  margin-top: 2rem;

  ${withTheme({
    color: "dark",
    fontSize: "h4",
    fontFamily: "primary"
  })}

  ${lg} {
    ${withTheme({
      fontSize: "h5"
    })}
  }

  ${sm} {
    margin-top: 1rem;
    ${withTheme({
      fontSize: "p"
    })}
  }
`

const CTALink = styled(Link)`
  margin-top: 1rem;
  font-weight: 700;
  outline: none;
  background: transparent;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    ${withTheme({
      color: "red"
    })}
  }

  ${withTheme({
    fontSize: "link",
    color: "red"
  })}

  ${sm} {
    ${withTheme({
      fontSize: "1"
    })}
  }
`

export default props => {
  return (
    <>
      <PageHeaderSection>
        <PageHeaderContainer>
          <PageHeading>Our Mission, Vision and Values </PageHeading>
          <PageSubheading>
            Guiding principles that matter most to us, Our purpose and long term
            goals.
          </PageSubheading>
        </PageHeaderContainer>
      </PageHeaderSection>

      <StatementSection />

      <BeliefSection />

      <ValuesSection />

      <TeamSection />
    </>
  )
}
