import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

const ValueCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 4rem 2.5rem 2.5rem;
  border-radius: 1.6rem;
  background: #ffffff;
  /* Drop Shawdow */
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);

  ${sm} {
    padding: 3rem 2rem 2rem;
  }
`

const ValueCardImage = styled.img`
  max-height: 10rem;
  width: auto;

  ${sm} {
    max-height: 6rem;
  }
`

const ValueCardHeading = styled.h5`
  margin-top: 2rem;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;

  ${systemCss({
    fontSize: ["link", "h5"],
    color: "dark"
  })}

  ${sm} {
    margin-top: 1.6rem;
  }
`

export default props => {
  const { illustration, heading } = props

  return (
    <ValueCard>
      <ValueCardImage src={illustration} alt="core value illustration" />
      <ValueCardHeading>{heading}</ValueCardHeading>
    </ValueCard>
  )
}
