import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

// Import assets
import DepthInfinityElement2 from "../../../assets/images/illustrations/depth-infinity-element2.svg"

import MissionIllustrationImg from "../../../assets/images/illustrations/tenxgeeks-mission-illustration.svg"
import VisionIllustrationImg from "../../../assets/images/illustrations/tenxgeeks-vision-illustration.svg"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

// Styles
const StatementSection = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;

  background-image: url(${DepthInfinityElement2});
  background-position: left -6rem top 50%;
  background-size: 22rem auto;
  background-repeat: no-repeat;

  ${xl} {
    background-position: left -4rem top 50%;
    background-size: 18rem auto;
  }
  ${md} {
    background-position: left -3rem top 50%;
    background-size: 12rem auto;
  }

  ${sm} {
    background-position: left -2rem top 50%;
    background-size: 8rem auto;
  }
`

const StatementContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1440px;
  margin: 0px auto;
  padding: 8rem 7rem 7rem;

  ${xl} {
    padding: 5rem 4rem 7rem;
  }

  ${lg} {
    padding: 4rem 4rem 5rem;
  }

  ${sm} {
    padding: 2rem 2rem 3rem;
  }
`

const MissionSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  ${lg} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
`

const VisionSection = styled(MissionSection)`
  margin-top: 16rem;

  ${xl} {
    margin-top: 12rem;
  }

  ${lg} {
    margin-top: 8rem;
  }

  ${sm} {
    margin-top: 6rem;
  }
`

const MissionContent = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${lg} {
    max-width: 95%;
    order: 1;
    margin-top: 4rem;
  }

  ${sm} {
    margin-top: 2.4rem;
    max-width: 100%;
  }
`

const VisionContent = styled(MissionContent)`
  order: 1;
`

const MissionHeading = styled.h4`
  line-height: 1.25;
  font-weight: 700;

  ${systemCss({
    fontSize: ["link", "h5", "h5", "h4"],
    color: "purple"
  })}
`

const VisionHeading = styled(MissionHeading)``

const MissionSubheading = styled.div`
  margin-top: 1rem;
  font-weight: 700;

  ${systemCss({
    fontSize: ["h5", "h5", "h4", "h3"],
    color: "black"
  })}

  ${lg} {
    margin-top: 1.6rem;
  }

  ${sm} {
    margin-top: 0.8rem;
  }
`

const VisionSubheading = styled(MissionSubheading)``

const MissionDescription = styled.div`
  margin-top: 2rem;
  line-height: 1.25;

  ${systemCss({
    fontSize: ["link", "p"],
    color: "black"
  })}

  ${lg} {
    margin-top: 1.6rem;
  }

  ${sm} {
    margin-top: 0.8rem;
  }
`

const VisionDescription = styled(MissionDescription)``

const MissionIllustration = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  padding: 0px 1rem;
  align-items: center;
  justify-content: center;

  & > img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  ${lg} {
    & > img {
      max-width: 68rem;
      max-height: 60rem;
    }
  }
`

const VisionIllustration = styled(MissionIllustration)`
  & > img {
    display: block;
    width: 100%;
    max-width: 48rem;
    height: auto;
    height: auto;
  }
`

export default () => {
  return (
    <StatementSection>
      <StatementContainer>
        {/* Mission section */}
        <MissionSection>
          <MissionContent>
            <MissionHeading>Mission</MissionHeading>
            <MissionSubheading>
              To deliver seamless, consistent & engaging digital experiences by
              using process-driven methodologies.
            </MissionSubheading>
            <MissionDescription>
              We use procedures that have been tested over time and proven to
              help overcome problems. It is a very well organized and
              well-researched plan to solve a problem. We optimize our processes
              after every project we deliver.
            </MissionDescription>
          </MissionContent>
          <MissionIllustration>
            <img
              src={MissionIllustrationImg}
              alt="mission statement illustration"
            />
          </MissionIllustration>
        </MissionSection>

        {/* Vision Section */}
        <VisionSection>
          <VisionContent>
            <VisionHeading>Vision</VisionHeading>
            <VisionSubheading>
              To be the most heard, reliable, and achieved brand when you talk
              about digital experiences and problem-solving.
            </VisionSubheading>
            <VisionDescription>
              Digital experience strategy isn’t an IT-driven initiative; it’s a
              customer-needs driven initiative. There’s a huge difference
              between simply using digital technology and leveraging it to
              improve customer experiences and better address customer needs.
            </VisionDescription>
          </VisionContent>
          <VisionIllustration>
            <img
              src={VisionIllustrationImg}
              alt="vision statement illustration"
            />
          </VisionIllustration>
        </VisionSection>
      </StatementContainer>
    </StatementSection>
  )
}
