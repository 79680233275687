import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

// Import assets
import DepthRoundElement from "../../../assets/images/illustrations/depth-round-element.svg"
import AkshaySnap from "../../../assets/images/core-members/tenxgeeks-team-akshay-saini.jpg"
import NikhilSnap from "../../../assets/images/core-members/tenxgeeks-team-nikhil-sindhu.jpg"
import LinkedinIcon from "../../../assets/images/icons/tenxgeeks-linkedin-icon-black.svg"
import GithubIcon from "../../../assets/images/icons/tenxgeeks-github-icon-black.svg"
import TwitterIcon from "../../../assets/images/icons/tenxgeeks-twitter-icon-black.svg"
import DribbbleIcon from "../../../assets/images/icons/tenxgeeks-dribbble-icon-black.svg"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

// Import components
import TeamMemberCard from "../../molecules/TeamMemberCard"

// Styles
const TeamSection = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;

  background-image: url(${DepthRoundElement});
  background-position: left 10% bottom -14rem;
  background-size: 28rem auto;
  background-repeat: no-repeat;

  ${xl} {
    background-position: left 10% bottom -11rem;
    background-size: 22rem auto;
  }

  ${lg} {
    background-position: left 10% bottom -8rem;
    background-size: 16rem auto;
  }
`

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 1440px;
  margin: 0px auto;
  padding: 7rem 7rem 14rem;

  background-image: url(${DepthRoundElement});
  background-position: right 12rem top 10rem;
  background-size: 24rem auto;
  background-repeat: no-repeat;

  ${xl} {
    padding: 5rem 4rem 10rem;

    background-position: right 0 top 12.5rem;
    background-size: 14rem auto;
  }

  ${lg} {
    background-position: right 0 top 10rem;
    background-size: 10rem auto;
  }

  ${sm} {
    padding: 3rem 2rem 6rem;

    background-position: right 0 top 12.5rem;
    background-size: 8rem auto;
  }
`

const SectionHeading = styled.h2`
  text-align: center;
  font-weight: 700;
  line-height: 1.25;

  ${systemCss({
    fontSize: ["h3", "h2"],
    fontFamily: "primary",
    color: "purple"
  })}
`

const TeamWrapper = styled.div`
  width: 100%;
  margin-top: 8rem;
  display: grid;
  grid-gap: 7rem;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 44rem));
  grid-auto-rows: auto;
  justify-content: center;

  ${xl} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${lg} {
    margin-top: 4rem;
  }

  ${md} {
    grid-gap: 4rem;
    grid-template-columns: 1fr;
  }
`

const teamMembers = [
  {
    name: "Akshay Saini",
    designation: "10x Designer",
    image: AkshaySnap,
    headline:
      "Akshay is a full-stack designer with outstanding work experience across the product development lifecycle. He specialized in branding, UI/UX, and creative consulting. His goal is to help brands build authenticity, clarity, and purpose through design.",
    socialHandles: [
      {
        icon: DribbbleIcon,
        url: "https://dribbble.com/akssmax"
      },

      {
        icon: LinkedinIcon,
        url: "https://www.linkedin.com/in/akssmax/"
      },
      {
        icon: TwitterIcon,
        url: "https://twitter.com/akshayakss"
      }
    ]
  },
  {
    name: "Nikhil Sindhu",
    designation: "10x Developer",
    image: NikhilSnap,
    headline:
      "Nikhil is a proficient problem solver with experience in full-stack, scalable, efficient web application development. He hacks and builds stuff using React, Flutter, Node.js, Graphql, etc. His goal is to help businesses build a robust, scalable and performant applications.",
    socialHandles: [
      {
        icon: GithubIcon,
        url: "https://github.com/nikhilsindhu"
      },
      {
        icon: LinkedinIcon,
        url: "https://www.linkedin.com/in/nikhil-sindhu-79473919b/"
      },
      {
        icon: TwitterIcon,
        url: "https://twitter.com/Nik54898048"
      }
    ]
  }
]

export default () => {
  return (
    <TeamSection>
      <TeamContainer>
        <SectionHeading>Core Team Members</SectionHeading>
        <TeamWrapper>
          {Array.isArray(teamMembers) &&
            teamMembers.map((member, i) => {
              const {
                name,
                designation,
                image,
                headline,
                socialHandles
              } = member

              return (
                <TeamMemberCard
                  key={i}
                  name={name}
                  designation={designation}
                  headline={headline}
                  image={image}
                  socialHandles={socialHandles}
                />
              )
            })}
        </TeamWrapper>
      </TeamContainer>
    </TeamSection>
  )
}
